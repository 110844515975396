import * as React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Layout from '../../components/layout/Layout';
import './blog.css';

const BlogPost = ({
  data: {
    mdx: {
      frontmatter: { title, date, embeddedImagesLocal },
      body,
    },
  },
}) => {
  return (
    <Layout>
      <section className="markdown pb-14 pt-0 lg:pb-30">
        <div className="container mx-auto px-20 sm:px-42 lg:px-60">
          <h1 className="mt-5 mb-2 text-2xl lg:text-4xl font-semibold">{title}</h1>
          <p>{date}</p>
          <MDXRenderer localImages={embeddedImagesLocal}>{body}</MDXRenderer>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        date(formatString: "DD/MM/YYYY")
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default BlogPost;
